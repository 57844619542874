import React from 'react';
import Tile from '../../../resources/js/components/Tile/Tile';
import Markdown from '../../../resources/js/components/Markdown/Markdown';
import VerticalContent from '../../../resources/js/components/VerticalContent/VerticalContent';

type ErrorPageProps = {
  title: number | string;
  text: string;
};

export default function ErrorPage({
  title,
  text,
}: ErrorPageProps): JSX.Element {
  // FIXME: Get trans localizations working in Next.js in a performant manner
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (global as any).locale = 'en';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (global as any).trans = (x: string) => x;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div style={{ width: 300 }}>
        <Tile title={title}>
          <VerticalContent>
            <Markdown openLinksExternally={false}>{`${text}

[Home](/)`}</Markdown>
          </VerticalContent>
        </Tile>
      </div>
    </div>
  );
}
